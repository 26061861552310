'use client';

import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { PostPaginationType, usePosts, type Post } from '../../services/postService';

import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import PostCard from './components/card';
import PostNavigation from './components/navigation';
import { CreatePostContextProvider } from '../../providers/create-post-provider';
import Loader from '~/ui/loader';
import { Popover } from '~/ui/popover';
import { Sheet, SheetTrigger } from '~/ui/sheet';
import { PlusCircledIcon } from '@radix-ui/react-icons';
import { Button } from '~/ui/button';

const defaultPostPaginationParams: PostPaginationType = { start: 0, limit: 25, order: 'desc', afterId: 0 };

const HomePage: React.FC = () => {
  const [postPaginationParams, setPostPaginationParams] = useQueryParams({
    start: withDefault(NumberParam, defaultPostPaginationParams.start, true),
    limit: withDefault(NumberParam, defaultPostPaginationParams.limit, true),
    order: withDefault(StringParam, defaultPostPaginationParams.order, true),
    afterId: withDefault(NumberParam, defaultPostPaginationParams.afterId, true),
  }) as unknown as [PostPaginationType, Dispatch<SetStateAction<PostPaginationType>>];

  const { data: postResponse, isError, isFetching } = usePosts(postPaginationParams);

  const [expandedPosts, setExpandedPosts] = useState<number[]>([]);

  const handleExpandPost = (id: number) => {
    setExpandedPosts((prev) => (prev.includes(id) ? prev.filter((p) => p !== id) : [...prev, id]));
  };
  const [createOpen, setCreateOpen] = useState(false);
  function closeCreateSheet() {
    setCreateOpen(false);
  }
  return (
    <CreatePostContextProvider>
      <Sheet open={createOpen} onOpenChange={setCreateOpen}>
        <Loader isFetching={isFetching} isError={isError}>
          <Popover>
            {postResponse?.posts && postResponse.posts.length === 0 && <div>No posts found</div>}
            <div className='h-full overflow-scroll overscroll-none'>
              <SheetTrigger
                asChild
                tabIndex={-1}
                className='flex items-center w-full gap-2 mb-2 overflow-hidden md:hidden touch-none overscroll-none'
              >
                <Button variant='tertiary' className='overflow-hidden rainbowText touch-none overscroll-none'>
                  <PlusCircledIcon className='w-8 h-8' />
                  <span className='text-nowrap md:block'>New Post</span>
                </Button>
              </SheetTrigger>
              <div className='flex flex-col pb-2 md:px-1 gap-y-2'>
                {postResponse?.posts &&
                  postResponse?.posts.map((post: Post) => (
                    <PostCard
                      key={post.id}
                      post={post}
                      expanded={expandedPosts.includes(post.id)}
                      onToggleExpand={handleExpandPost}
                    />
                  ))}
              </div>
            </div>
          </Popover>
        </Loader>

        <PostNavigation
          closeCreateSheet={closeCreateSheet}
          postPaginationParams={postPaginationParams}
          setPostPaginationParams={setPostPaginationParams}
          pageCount={postResponse?.pageCount || 0}
        />
      </Sheet>
    </CreatePostContextProvider>
  );
};

export default HomePage;
