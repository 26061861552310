import { useRef, useState, useEffect } from 'react';
import { EnterFullScreenIcon, ExitFullScreenIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { dataStoreToString, encodingToString, maxPostTextLength, postDateFormatter } from 'utils/post';
import { Post } from 'services/postService';
import { Button } from '~/ui/button';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '~/ui/card';
import { Popover, PopoverTrigger, PopoverContent } from '~/ui/popover';
import MarkdownProcessor from './markdown-processor';

type PostProps = {
  post: Post;
  expanded: boolean;
  onToggleExpand: (id: number) => void;
};

function PostCard({ post, expanded, onToggleExpand }: PostProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.scrollHeight > contentRef.current.clientHeight ||
          contentRef.current.scrollWidth > contentRef.current.clientWidth,
      );
    }
  }, [contentRef, post.data, expanded]);

  return (
    <Card key={post.id} className={`flex  flex-col ${expanded ? '' : 'max-h-80'}`}>
      <CardHeader className='px-4 py-2 '>
        <CardTitle className='flex items-center justify-between gap-4 '>
          <div className='text-sm md:text-base'>
            <span>{post.id}</span> <span>{postDateFormatter(new Date(post.date * 1000), false)}</span>
          </div>
          <Popover>
            <PopoverTrigger asChild className='block'>
              <Button type='button' className='flex' variant={'link'}>
                <QuestionMarkCircledIcon className='w-4 h-4' />
              </Button>
            </PopoverTrigger>
            <PopoverContent className='z-50 flex flex-col gap-4 overscroll-none touch-none'>
              <span>ID: {post.id}</span>
              <span>Date: {postDateFormatter(new Date(post.date * 1000), false)}</span>
              <span className='flex gap-4 text-sm text-nowrap '>
                <span>
                  {encodingToString(post.encoding)} / {dataStoreToString(post.datastore)}
                </span>
              </span>
              <span className='flex gap-4 text-sm text-nowrap '>
                <span>{post.data.length}</span> / <span>{maxPostTextLength} Characters</span>
              </span>
            </PopoverContent>
          </Popover>
        </CardTitle>
      </CardHeader>
      <CardContent ref={contentRef} className='flex p-4 overflow-hidden '>
        <span
          className='font-sans text-lg text-gray-300 break-all whitespace-pre-line'
          style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 1))' }}
        >
          <MarkdownProcessor>{post.data}</MarkdownProcessor>
        </span>
      </CardContent>
      <CardFooter className={`items-end pt-2 ${isOverflowing ? 'border-t' : ''} border-gray-700`}>
        {isOverflowing && !expanded && (
          <Button className='p-0 h-fit' variant={'link'} onClick={() => onToggleExpand(post.id)}>
            <EnterFullScreenIcon className='w-5 h-5 ' />
            Expand Post
          </Button>
        )}
        {expanded && (
          <Button className='p-0 h-fit' variant={'link'} onClick={() => onToggleExpand(post.id)}>
            <ExitFullScreenIcon className='w-5 h-5 ' />
            Minimize Post
          </Button>
        )}
        <div className='flex items-center justify-end w-full text-gray-400'>
          <span>{post.data.length}</span> / <span>{maxPostTextLength}</span>
        </div>
      </CardFooter>
    </Card>
  );
}

export default PostCard;
